import ContentMargins from "../components/layout/ContentMargins";
import Layout from "../components/layout/layout"
import PageTitle from "../components/contentHeadings/PageTitle";
import React from "react"
import Seo from "../components/Seo"


const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Ikke funnet"/>
        <ContentMargins>
            <PageTitle>404: Ikke funnet</PageTitle>
            <p>Vi finner ikke noe innhold under denne url-adressen.</p>
        </ContentMargins>
    </Layout>
)

export default NotFoundPage
